// Load Styles
import "../scss/main.scss";
// Load Bootstrap init
import {initBootstrap} from "./bootstrap";
import AOS from "aos";
// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true
});

// Load Google Fonts

var WebFont = require("webfontloader");

WebFont.load({
  google: {
    families: ["Rubik"]
  }
});
AOS.init();

// Load jQuery
var $ = require("jquery");
$(document).ready(function () {});
var videoBtn = document.querySelector(".video-btn");
var videoModal = document.getElementById("myModal");
var video = document.getElementById("video");
var videoSrc;

videoBtn.addEventListener("click", function (e) {
  videoSrc = videoBtn.getAttribute("data-bs-src");
});

videoModal.addEventListener("shown.bs.modal", e => {
  video.setAttribute("src", videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");
});

videoModal.addEventListener("hide.bs.modal", e => {
  video.setAttribute("src", videoSrc);
});
